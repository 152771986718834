import React, { useEffect } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types"
import "../styles/theme.scss"
import NavBar from "./navbar"
import Footer from "./footer"
import CookieConsent from 'react-cookie-consent';
import { useLocation } from "@reach/router" 
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "de";
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "de";

  switch (lang) {
    case "en":
      return "en";
    default:
      return "de";
  }
};

export default function Layout({ children }) {
  // see also https://stackoverflow.com/a/60293794/8610344
  // useEffect(() => {
  //   const urlLang = getRedirectLanguage();

  //   navigate(`/${urlLang}/`, {replace: true});
  // }, []);
  return (

    <>
      <NavBar />
      <main id="content" role="main">
        {children}
      </main>
      <Footer />
      <CookieConsent
        location= "bottom"
        disableButtonStyles = {true}
        buttonClasses = "btn btn-primary m-5"
        declineButtonClasses = "btn btn-secondary y-5"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="CookieConsent"
        enableDeclineButton
        onAccept={() => {
          initializeAndTrack(location) 
        }}>
        This site uses cookies, please consent ...
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}