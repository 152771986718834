import React from "react"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"
import Flags from 'country-flag-icons/react/1x1'

export default function Footer() {

  return (
    <footer className="bg-dark">
      <div className="container">
        <div className="row align-items-center pt-8 pb-4">
          <div className="col-md mb-5 mb-md-0">
            <h2 className="fw-medium text-white-70 mb-0">Join the thriving<br /><span className="fw-bold text-white">Unify</span> business agency</h2>
          </div>
          {/*  End Col  */}

          <div className="col-md-auto">
            <div className="d-grid d-sm-flex gap-3">
              <a className="btn btn-primary" href="#">Request demo</a>
              <a className="btn btn-ghost-light btn-pointer" href="#">Sign up free</a>
            </div>
          </div>
          {/*  End Col  */}
        </div>
        {/*  End Row  */}

        <div className="border-bottom border-white-10">
          <div className="row py-6">
            <div className="col-6 col-sm-4 col-lg mb-7 mb-lg-0">
              <span className="text-cap text-white">Resources</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-1 mb-0">
                <li><a className="link link-light link-light-75" href="#">Blog</a></li>
                <li><a className="link link-light link-light-75" href="#">Guidance</a></li>
                <li><a className="link link-light link-light-75" href="#">Customer Stories</a></li>
                <li><a className="link link-light link-light-75" href="#">Support <i className="bi-box-arrow-up-right ms-1"></i></a></li>
                <li><a className="link link-light link-light-75" href="#">API</a></li>
                <li><a className="link link-light link-light-75" href="#">Packages</a></li>
              </ul>
              {/*  End List  */}
            </div>
            {/*  End Col  */}

            <div className="col-6 col-sm-4 col-lg mb-7 mb-lg-0">
              <span className="text-cap text-white">Company</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-1 mb-0">
                <li><a className="link link-light link-light-75" href="#">Belonging <i className="bi-box-arrow-up-right ms-1"></i></a></li>
                <li><a className="link link-light link-light-75" href="#">Company</a></li>
                <li><a className="link link-light link-light-75" href="#">Careers</a> <span className="fs-6 fw-bold text-primary">&mdash; We're hiring</span></li>
                <li><a className="link link-light link-light-75" href="#">Contacts</a></li>
                <li><a className="link link-light link-light-75" href="#">Security</a></li>
              </ul>
              {/*  End List  */}
            </div>
            {/*  End Col  */}

            <div className="col-6 col-sm-4 col-lg mb-7 mb-sm-0">
              <span className="text-cap text-white">Platform</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-1 mb-0">
                <li><a className="link link-light link-light-75" href="#">Web</a></li>
                <li><a className="link link-light link-light-75" href="#">Mobile</a></li>
                <li><a className="link link-light link-light-75" href="#">iOS</a></li>
                <li><a className="link link-light link-light-75" href="#">Android</a></li>
                <li><a className="link link-light link-light-75" href="#">Figma Importing</a></li>
              </ul>
              {/*  End List  */}
            </div>
            {/*  End Col  */}

            <div className="col-6 col-sm-4 col-lg mb-7 mb-sm-0">
              <span className="text-cap text-white">Legal</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-1 mb-5">
                <li><a className="link link-light link-light-75" href="#">Terms of use</a></li>
                <li><a className="link link-light link-light-75" href="#">Privacy policy <i className="bi-box-arrow-up-right ms-1"></i></a></li>
              </ul>
              {/*  End List  */}

              <span className="text-cap text-white">Docs</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-1 mb-0">
                <li><a className="link link-light link-light-75" href="#">Documentation</a></li>
                <li><a className="link link-light link-light-75" href="#">Snippets</a></li>
              </ul>
              {/*  End List  */}
            </div>
            {/*  End Col  */}

            <div className="col-6 col-sm-4 col-lg">
              <span className="text-cap text-white">Follow us</span>

              {/*  List  */}
              <ul className="list-unstyled list-py-2 mb-0">
                <li><a className="link link-light link-light-75" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi-envelope-open-fill"></i>
                    </div>

                    <div className="flex-grow-1 ms-2">
                      <span>Subscribe by email</span>
                    </div>
                  </div>
                </a></li>

                <li><a className="link link-light link-light-75" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi-facebook"></i>
                    </div>

                    <div className="flex-grow-1 ms-2">
                      <span>Facebook</span>
                    </div>
                  </div>
                </a></li>

                <li><a className="link link-light link-light-75" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi-linkedin"></i>
                    </div>

                    <div className="flex-grow-1 ms-2">
                      <span>Linkedin</span>
                    </div>
                  </div>
                </a></li>

                <li><a className="link link-light link-light-75" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi-twitter"></i>
                    </div>

                    <div className="flex-grow-1 ms-2">
                      <span>Twitter</span>
                    </div>
                  </div>
                </a></li>

                <li><a className="link link-light link-light-75" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bi-slack"></i>
                    </div>

                    <div className="flex-grow-1 ms-2">
                      <span>Slack</span>
                    </div>
                  </div>
                </a></li>
              </ul>
              {/*  End List  */}
            </div>
            {/*  End Col  */}
          </div>
          {/*  End Row  */}
        </div>

        <div className="row align-items-md-center py-6">
          <div className="col-md mb-3 mb-md-0">
            {/*  List  */}
            <ul className="list-inline list-px-2 mb-0">
              <li className="list-inline-item"><a className="link link-light link-light-75" href="#">Privacy and Policy</a></li>
              <li className="list-inline-item"><a className="link link-light link-light-75" href="#">Terms</a></li>
              <li className="list-inline-item"><a className="link link-light link-light-75" href="#">Status</a></li>
              <li className="list-inline-item">
                {/*  Button Group  */}
                <div className="btn-group">
                  <a className="link link-light link-light-75" href="javascript:;" id="selectLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="d-flex align-items-center">
                      <Flags.US title="English" className="avatar avatar-xss avatar-circle me-2" />
                      <span>English</span>
                    </span>
                  </a>

                  <div className="dropdown-menu">
                    <a className="dropdown-item d-flex align-items-center active" href="#">
                      <Flags.US title="English" className="avatar avatar-xss avatar-circle me-2" />
                      <span>English</span>
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                      <Flags.DE title="Deutsch" className="avatar avatar-xss avatar-circle me-2" /><span>Deutsch</span>
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                      <Flags.ES title="Español" className="avatar avatar-xss avatar-circle me-2" />
                      <span>Español</span>
                    </a>
                  </div>
                </div>
                {/*  End Button Group  */}
              </li>
            </ul>
            {/*  End List  */}
          </div>
          {/*  End Col  */}

          <div className="col-md-auto">
            <p className="fs-5 text-white-70 mb-0">© Ismaili. 2022</p>
          </div>
          {/*  End Col  */}
        </div>
        {/*  End Row  */}
      </div>
    </footer>

  )
}